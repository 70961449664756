let EfbHelperClass = (function () {

    let _private = {};

    _private.isInit       = false;
    _private.translations = {};
    _private.spinners = [];

    _private.instance = this;

    this.numericKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

    this.init = function () {
        if (_private.isInit) {
            return;
        }
        _private.isInit = true;
    };

    this.setTranslations = function (translations) {
        _private.translations = translations;
    };

    this.addLoadingSpinner = function (element, key = 'default') {
        if (_.isUndefined(element)) {
            return;
        }
        element = $(element);
        element.addClass('loading-spinner-wrap')
        if (element.css('position') === 'static') {
            element.css('position', 'relative');
        }
        $('div.state-control div.loader').show();
        if (element.length === 0) {
            return;
        }
        let spinnerOptions = {
            'class': 'spinner',
            'style': 'width: calc(100%);height: calc(100%);z-index: 100;'
        };
        let spinner        = element.find('div.spinner').length ? element.find('div.spinner') : $('<div/>', spinnerOptions);
        _private.spinners.push(key);
        if ($(element).is('tr')) {
            let pos = element.position();
            spinner.css('left', pos.left + 'px');
            spinner.css('top', pos.top + 'px');
        }
        element.append(spinner);
    };

    this.removeLoadingSpinner = function (element, key = 'default') {
        if (_.isUndefined(element)) {
            return true;
        }
        _private.spinners = _private.spinners.filter(s => s !== key);
        if (_private.spinners.length) {
            return false;
        }
        $(element).removeClass('loading-spinner-wrap').find('div.spinner').remove();
        $('div.state-control div.loader').hide();
        return true;
    };

    this.openSectionsWithErrors = function (content) {
        $(content).find('div.section').each(function (i, section) {
            if ($(section).find('div.formelement.error').length) {
                $(section).show();
                $(section).prev('div.section-toggler').find('a').addClass('open');
            }
        });
    };

    this.showErrorResult = function (element, parameters) {
        _private.instance.removeLoadingSpinner(element);
        let errorHtml = '';
        let title     = '!!!!!!!!!! ERROR !!!!!!!!!!!';
        if (!_.isUndefined(parameters.responseText) && parameters.responseText !== '') {
            parameters.responseText = jQuery.parseJSON(parameters.responseText);
            let txt                 = _private.instance.print_r(parameters.responseText);
            errorHtml += '<pre>' + txt + '</pre>';
        }
        if (errorHtml === '') {
            return;
        }
        _private.instance.dialog({
            'width':        1000,
            'resizable':    true,
            'title':        title,
            'closeOutside': true,
            'zIndex':       100000,
            'html':         errorHtml
        });
    };

    this.showStatusMessage = function (element, status) {
        let className = '';
        if (typeof status === 'undefined') {
            return;
        }
        if (typeof status.key !== 'undefined') {
            status = status.key;
        }
        switch (true) {
            case status.indexOf('save_ok') !== -1:
            case status.indexOf('delete_ok') !== -1:
            case status.indexOf('success') !== -1:
                className = 'success';
                break;
            case status.indexOf('save_conflict') !== -1:
            case status.indexOf('check_errors') !== -1:
            case status.indexOf('delete_conflict') !== -1:
            case status.indexOf('validation_error') !== -1:
                className = 'warning';
                break;
            case status.indexOf('save_error') !== -1:
                className = 'fatalerror';
                break;
            default:
                break;
        }
        $(element).find('.statusMessage').remove();
        let objectInfo = $(element).find('div.object-info');
        if (objectInfo.length === 0) {
            $(element).prepend(jQuery('<div/>', {'class': 'object-info'}));
            objectInfo = $(element).find('div.object-info');
        }
        if (objectInfo.length > 0) {
            let html = _private.instance._('status.' + status);
            jQuery('<div/>', {'class': 'statusMessage ' + className, html: html}).insertAfter(objectInfo);
        }
        if ($(objectInfo).html() === '') {
            $(objectInfo).hide();
        }
        if (className === 'success') {
            setTimeout(function () {
                $(element).find('.statusMessage').fadeOut('fast');

            }, 1000, element);
        }
        $(element).find('.statusMessage').click(function () {
            $(this).fadeOut('fast');
        });
    };


    /**
     * Convert a string to Date-object
     *
     * @param str string
     * @return Date
     */
    this.strToDate = function (str) {

        let date = new Date();
        if (!/\d{4}-[0-1]\d-[0-3]\d\s[0-2]\d:[0-5]\d:[0-5]\d/.test(str)) {
            return date;
        }
        date.setFullYear(0);
        date.setMonth(0);
        date.setDate(0);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        if (typeof str !== 'string') {
            return date;
        }
        if (str.length < 8) {
            return date;
        }
        str = str.split(' ');

        let dateParts = str[0].split('-');
        if (dateParts[0] !== 'undefined' && dateParts[0].length === 4) {
            date.setUTCFullYear(parseInt(dateParts[0]));
        }
        if (dateParts[1] !== 'undefined' && dateParts[1].length === 2) {
            date.setUTCMonth(parseInt(dateParts[1], 10) - 1);
        }
        if (dateParts[2] !== 'undefined' && dateParts[2].length === 2) {
            date.setUTCDate(parseInt(dateParts[2], 10));
        }
        if (typeof str[1] === 'undefined') {
            return date;
        }
        let timeParts = str[1].split(':');
        if (typeof timeParts[0] !== 'undefined') {
            date.setUTCHours(parseInt(timeParts[0], 10));
        }
        if (typeof timeParts[1] !== 'undefined') {
            date.setUTCMinutes(parseInt(timeParts[1], 10));
        }
        if (typeof timeParts[2] !== 'undefined') {
            date.setUTCSeconds(parseInt(timeParts[2], 10));
        }
        return date;
    };

    this.timeStringToMinutes = function (str) {
        if (_.isUndefined(str)) {
            return 0;
        }
        str = str.split(':');
        if (str.length !== 2) {
            return 0;
        }
        let hours   = parseInt(str[0], 10);
        let minutes = parseInt(str[1], 10);
        return (minutes + hours * 60);
    };

    // noinspection JSUnusedGlobalSymbols
    this.secondsToTimeString = function (seconds) {
        if (_.isUndefined(seconds)) {
            return '0h0';
        }
        let hours   = seconds > 3600 ? Math.floor(seconds / 3600) + 'h' : '0h';
        let minutes = _private.instance.zeroPad(Math.floor(seconds % 3600 / 60), 2);
        return hours + minutes;
    };

    // noinspection JSUnusedGlobalSymbols
    this.timeStringToSeconds = function (timeString) {
        let seconds = 0;
        let match   = /(\d*)h(\d*)|(\d*)m/.exec(timeString);
        if (!match) {
            return seconds;
        }
        if (!_.isUndefined(match[1])) {
            seconds += parseInt(match[1]) * 3600;
        }
        if (!_.isUndefined(match[2])) {
            seconds += parseInt(match[2]) * 60;
        }
        if (!_.isUndefined(match[3])) {
            seconds += parseInt(match[3]) * 60;
        }
        return seconds;
    };

    // noinspection JSUnusedGlobalSymbols
    this.getLocalDate = function (date) {
        if (typeof date === 'string') {
            date = _private.instance.strToDate(date);
        }
        if (!(date instanceof Date)) {
            console.log(date);
            return 'invalid Format';
        }
        return $.datepicker.formatDate(localDateFormat, date);
    };

    this.getDateString = function (date, format, utc) {
        let months        = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            getPaddedComp = function (comp) {
                return ((parseInt(comp) < 10) ? ('0' + comp) : comp)
            },
            getDatePart   = function (part) {
                part = utc ? 'UTC' + part : part;
                return date['get' + part]();
            },
            formattedDate = format,
            o             = {
                "y+": getDatePart('FullYear'), // year
                "M+": months[getDatePart('Month')], //month
                "d+": getPaddedComp(getDatePart('Date')), //day
                "h+": getPaddedComp((getDatePart('Hours') > 12) ? getDatePart('Hours') % 12 : getDatePart('Hours')), //hour
                "H+": getPaddedComp(getDatePart('Hours')), //hour
                "m+": getPaddedComp(getDatePart('Minutes')), //minute
                "s+": getPaddedComp(getDatePart('Seconds')), //second
                "S+": getPaddedComp(getDatePart('Milliseconds')), //millisecond,
                "b+": (getDatePart('Hours') >= 12) ? 'PM' : 'AM'
            };

        for (let k in o) {
            if (new RegExp("(" + k + ")").test(format)) {
                formattedDate = formattedDate.replace(RegExp.$1, o[k]);
            }
        }
        return formattedDate;
    };

    this.scrollTo = function (to, element) {
        if (!$(element).length || _.isUndefined($(element).offset())) {
            return;
        }
        let headerOffset = $('div.header-navi').outerHeight() + $('div.content-header').outerHeight();
        let space        = 0;
        let options      = {};
        switch (to) {
            case 'top':
                let elementTop = $(element).offset().top;
                if (elementTop - space > headerOffset) {
                    space -= headerOffset;
                }
                options.scrollTop = elementTop + space;
                break;
        }
        $('html,body').animate(options, 200);
    };

    _private.getTooltipContent = function (element) {
        if (!$(element).is('div')) {
            element = $(element).parent('div.formelement');
        }
        let content = '';
        if ($(element).find("div.form-error li").length) {
            content = $(element).find("div.form-error").html();
        }
        return content;
    };

    this.round = function (num, decimals, zeroPad) {
        if (_.isUndefined(decimals)) {
            decimals = 0;
        }
        num = Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
        if (zeroPad && num > 0) {
            num = _private.instance.zeroPad(num, decimals);
        }
        return num;
    };

    this._ = function (translationKey, fallBack) {
        if (typeof translationKey !== "string") {
            translationKey = "undefined.translation";
        }
        if (typeof _private.translations[translationKey] === 'undefined') {
            if (!_.isUndefined(fallBack)) {
                return fallBack;
            }
            return translationKey;
        }
        return _private.translations[translationKey];
    };

    this.formatTime = function (seconds, asTimeField) {
        if (_.isNaN(seconds)) {
            seconds = 0;
        }
        let prefix = '';
        if (_.isUndefined(asTimeField)) {
            asTimeField = false;
        }
        if (asTimeField && seconds >= 86400) {
            seconds = seconds % 86400;
        }
        if (seconds < 0) {
            prefix  = '- ';
            seconds = seconds * -1;
        }
        let minutes = Math.floor(seconds / 60);
        let hours   = Math.floor(minutes / 60);
        minutes     = minutes % 60;
        return prefix + _private.instance.zeroPad(hours, 2) + ':' + _private.instance.zeroPad(minutes, 2);
    };

    this.zeroPad = function (num, places) {
        let zero = places - num.toString().length + 1;
        num      = new Array(+(zero > 0 && zero)).join("0") + num;
        return num;
    };

    // noinspection JSUnusedGlobalSymbols
    this.autocompleteTime = function (event) {
        let allowedKeys = _private.instance.numericKeyCodes;
        allowedKeys.push(58);
        if ($.inArray(event.keyCode, allowedKeys) === -1) {
            event.preventDefault();
        }
        let value         = $(event.target).val();
        let duplePointPos = value.indexOf(':');
        if (duplePointPos === -1) {
            if (value.length === 2 && event.keyCode !== 58) {
                $(event.target).val(value + ':');
            } else if (value.length > 2 && event.keyCode === 58) {
                event.preventDefault();
            }
        } else {
            if (event.keyCode === 58) {
                event.preventDefault();
            }
        }
        if (value === '' && event.keyCode === 58) {
            $(event.target).val('0:');
            event.preventDefault();
        }
    };

    // noinspection JSUnusedGlobalSymbols
    this.autocompleteTimeBlur = function (event) {
        let value = $(event.target).pVal();
        if ($.isNumeric(value) && value < 100) {
            value = EfbHelper.formatTime(value * 60, true);
        }
        $(event.target).val(value);
        Validator.validateTime($(event.target));
    };

    this.autocompleteTimeStampField = function (event) {
        let allowedKeys = _private.instance.numericKeyCodes;
        allowedKeys.push(58);
        if ($.inArray(event.keyCode, allowedKeys) === -1) {
            event.preventDefault();
        }
        let value = $(event.target).val();
        if (value.length === 2 && event.keyCode !== 58) {
            $(event.target).val(value + ':');
        } else if (value.length > 2 && event.keyCode === 58) {
            event.preventDefault();
        }
    };

    /**
     * Converts a date string like '2010-09-26' into a Date instance.
     *
     * Other than the Date() initializer, which would parse such a string as UTC,
     * this method assumes that the given string is in local time.
     *
     * @param {string} dateString - A string value representing a date,
     *  specified in a format recognized by the Date.parse() method. (These
     *  formats are IETF-compliant RFC 2822 timestamps, and also strings in a
     *  version of ISO8601.)
     * @returns {Date} - A new Date instance that represents the moment in time
     *  which is the beginning of the specified day.
     */
    this.localDateStringToDate = function (dateString) {
        let date = new Date(dateString);
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return date;
    };


    /**
     * PHP. Javascript. Print_r. Nice. Object. Dumper.
     * Original. Code: http://www.openjs.com/scripts/others/dump_function_php_print_r.php
     * Modified. By. Claude. Hohl. Namics.
     */
    this.print_r = function (arr, level) {
        let dumped_text = "";
        if (!level) level = 0;

        //The padding given at the beginning of the line.
        let level_padding         = "";
        let bracket_level_padding = "";

        for (let j = 0; j < level + 1; j++) level_padding += "    ";
        for (let b = 0; b < level; b++) bracket_level_padding += "    ";

        if (typeof (arr) === 'object') { //Array/Hashes/Objects
            dumped_text += "Array\n";
            dumped_text += bracket_level_padding + "(\n";
            for (let item in arr) {

                // noinspection JSUnfilteredForInLoop
                let value = arr[item];

                if (typeof (value) === 'object') { //If it is an array,
                    dumped_text += level_padding + "[" + item + "] => ";
                    dumped_text += _private.instance.print_r(value, level + 2);
                } else {
                    dumped_text += level_padding + "[" + item + "] => " + value + "\n";
                }

            }
            dumped_text += bracket_level_padding + ")\n\n";
        } else { //Stings/Chars/Numbers etc.
            dumped_text = "===>" + arr + "<===(" + typeof (arr) + ")";
        }

        return dumped_text;

    };

    this.initToggler = function (baseElement) {
        $(baseElement).find('div.toggler-wrap a.toggler')
                      .unbind('click.toggleSection').on('click.toggleSection', function (event) {
            $(event.target).parent('div').next().toggle('fast', function () {
                $(event.target).removeClass('open');
                let trigger = 'toggledClose';
                if ($(this).is(':visible')) {
                    $(event.target).addClass('open');
                    trigger = 'toggledOpen';
                }
                $(this).trigger(trigger);
            });
        });
        $(baseElement).find('a.toggler *')
                      .unbind('click.toggleSection').on('click.toggleSection', function (event) {
            $(event).parent('a.toggler').click();
        });
    };

    this.userAgent = function () {
        return UAParser();
    };

    this.underscoreToCamelCase = function (string) {
        return string.replace(/_([a-z])/g, function (g) {
            return g[1].toUpperCase();
        });
    };

    this.formatFileSize = function (size, precision) {
        let prefixes = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
        let result   = size;
        let index    = 0;
        if (_.isUndefined(precision)) {
            precision = 2;
        }
        precision = parseFloat(precision);
        while (result > 1024 && index < prefixes.length) {
            result = result / 1024;
            index++;
        }
        result = EfbHelper.round(result, precision);
        return result + ' ' + prefixes[index] + 'B';
    };

    this.parseJSON = function (jsonString) {
        let object = {};
        try {
            object = JSON.parse(jsonString);
        } catch (e) {
            console.log(e);
            console.log(jsonString);
        }
        return object;
    };

    this.generateUUID = function (long) {
        let d    = new Date().getTime();
        let tmpl = long ? 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx' : 'xxxxxxxxxxxxxx';
        return tmpl.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d     = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    };

    this.togglePseudoFullScreen = function () {
        let body = $('body');
        if (body.hasClass('full-screen-on')) {
            body.removeClass('full-screen-on');
            body.trigger('fullscreenchange')
        } else {
            body.addClass('full-screen-on');
            body.trigger('fullscreenchange')
        }
    };

    /**
     *
     * @param {string|number} key
     * @param {*} dataSource
     * @param {*} [fallBack]
     * @returns {*}
     */
    this.getValueByKey = function (key, dataSource, fallBack) {
        let config   = $.extend(true, {}, dataSource);
        let found    = fallBack;
        let keyParts = key.split('.');
        $.each(keyParts, function (i, k) {
            if (_.isUndefined(config[k]) || _.isNull(config[k])) {
                return false;
            }
            config = config[k];
            if (keyParts.length === (i + 1)) {
                found = config;
            }
        });
        return found;
    };

    // noinspection JSUnusedGlobalSymbols
    this.roundValueKeyup = function (event) {
        if ($.inArray(event.keyCode, [undefined, 37, 38, 39, 40, 9]) !== -1) {
            return;
        }
        let value = $(event.target).val();
        if (value.indexOf(',') !== -1) {
            let start = event.target.selectionStart,
                end   = event.target.selectionEnd;
            $(event.target).val(value.replace(',', '.'));
            event.target.setSelectionRange(start, end);
        }
        $(event.target).attr('pure-value', $(event.target).val());
    };


    // noinspection JSUnusedGlobalSymbols
    this.roundValueBlur = function (event) {
        let input = $(event.target);
        let data  = input.data();
        /** @namespace data.leaveEmpty */
        if ($.trim(input.val()) === '' && !_.isUndefined(data.leaveEmpty)) {
            input.uVal(data.leaveEmpty);
        }
    };

    this.deleteBrowserData = (indexDataBases = ['select-options-cache', 'document-tree']) => {
        indexDataBases.forEach(db => {
            try {
                window.indexedDB.deleteDatabase(db)
            } catch (e) {

            }
        });
        try {
            sessionStorage.clear();
            localStorage.clear();
        } catch (e) {

        }
    };

    this.versionCompare = (v1, v2, operator) => {
        //       discuss at: http://phpjs.org/functions/version_compare/
        //      original by: Philippe Jausions (http://pear.php.net/user/jausions)
        //      original by: Aidan Lister (http://aidanlister.com/)
        // reimplemented by: Kankrelune (http://www.webfaktory.info/)
        //      improved by: Brett Zamir (http://brett-zamir.me)
        //      improved by: Scott Baker
        //      improved by: Theriault
        //        example 1: version_compare('8.2.5rc', '8.2.5a');
        //        returns 1: 1
        //        example 2: version_compare('8.2.50', '8.2.52', '<');
        //        returns 2: true
        //        example 3: version_compare('5.3.0-dev', '5.3.0');
        //        returns 3: -1
        //        example 4: version_compare('4.1.0.52','4.01.0.51');
        //        returns 4: 1

        // END REDUNDANT
        // Important: compare must be initialized at 0.
        var i           = 0,
            x           = 0,
            compare     = 0,
            // vm maps textual PHP versions to negatives so they're less than 0.
            // PHP currently defines these as CASE-SENSITIVE. It is important to
            // leave these as negatives so that they can come before numerical versions
            // and as if no letters were there to begin with.
            // (1alpha is < 1 and < 1.1 but > 1dev1)
            // If a non-numerical value can't be mapped to this table, it receives
            // -7 as its value.
            vm          = {
                'dev':   -6,
                'alpha': -5,
                'a':     -5,
                'beta':  -4,
                'b':     -4,
                'RC':    -3,
                'rc':    -3,
                '#':     -2,
                'p':     1,
                'pl':    1
            },
            // This function will be called to prepare each version argument.
            // It replaces every _, -, and + with a dot.
            // It surrounds any nonsequence of numbers/dots with dots.
            // It replaces sequences of dots with a single dot.
            //    version_compare('4..0', '4.0') == 0
            // Important: A string of 0 length needs to be converted into a value
            // even less than an unexisting value in vm (-7), hence [-8].
            // It's also important to not strip spaces because of this.
            //   version_compare('', ' ') == 1
            prepVersion = function (v) {
                v = ('' + v)
                    .replace(/[_\-+]/g, '.');
                v = v.replace(/([^.\d]+)/g, '.$1.')
                     .replace(/\.{2,}/g, '.');
                return (!v.length ? [-8] : v.split('.'));
            };
        // This converts a version component to a number.
        // Empty component becomes 0.
        // Non-numerical component becomes a negative number.
        // Numerical component becomes itself as an integer.
        const numVersion = function (v) {
            return !v ? 0 : (isNaN(v) ? vm[v] || -7 : parseInt(v, 10));
        };
        v1         = prepVersion(v1);
        v2         = prepVersion(v2);
        x          = Math.max(v1.length, v2.length);
        for (i = 0; i < x; i++) {
            if (v1[i] == v2[i]) {
                continue;
            }
            v1[i] = numVersion(v1[i]);
            v2[i] = numVersion(v2[i]);
            if (v1[i] < v2[i]) {
                compare = -1;
                break;
            } else if (v1[i] > v2[i]) {
                compare = 1;
                break;
            }
        }
        if (!operator) {
            return compare;
        }

        // Important: operator is CASE-SENSITIVE.
        // "No operator" seems to be treated as "<."
        // Any other values seem to make the function return null.
        switch (operator) {
            case '>':
            case 'gt':
                return (compare > 0);
            case '>=':
            case 'ge':
                return (compare >= 0);
            case '<=':
            case 'le':
                return (compare <= 0);
            case '==':
            case '=':
            case 'eq':
                return (compare === 0);
            case '<>':
            case '!=':
            case 'ne':
                return (compare !== 0);
            case '':
            case '<':
            case 'lt':
                return (compare < 0);
            default:
                return null;
        }
    }

});
let EfbHelper      = new EfbHelperClass();
